<template>
    <Login></Login>
    <div>
    </div>
</template>
<script>
import Login from './Login.vue';
export default {
    components: { Login }
}
</script>
<style>
    
</style>